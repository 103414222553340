import React from 'react';
import { render } from 'react-dom';
import App from './js/App';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ProoviaAdminProvider } from './js/Context/context';

import './scss/App.scss';

render(
	// <React.StrictMode>
	<ProoviaAdminProvider>
		<App height="100%" width="100%" />
		<ToastContainer />
	</ProoviaAdminProvider>,
	// </React.StrictMode>
	document.getElementById('root')
);
