import { createContext, useReducer, useContext } from 'react';
import { Logger } from '../Utils/stateLogger';

const ProoviaAdminStateContext = createContext();
const ProoviaAdminDispatchContext = createContext();

const ProoviaAdminReducer = (state, action) => {
	const handler = handlers[action.type] || handlers.DEFAULT;
	return handler(state, action);
};
const handlers = {
	SET_LOGINED: (state, { payload }) => ({ ...state, logined: payload }),
	SET_TOKEN: (state, { payload }) => ({ ...state, token: payload }),
	SET_USER: (state, { payload }) => ({ ...state, user: payload }),
	SET_RIGHTS: (state, { payload }) => ({ ...state, rights: payload }),
	SHOW_SIDER: (state, { payload }) => ({ ...state, showSider: payload }),
	OVER_SIDER: (state, { payload }) => ({ ...state, overSider: payload }),
	SET_MODAL_ERROR: (state, { payload }) => ({ ...state, modalError: payload }),
	RESET: () => ({}),
	DEFAULT: (state, action) => ({ ...state, ...action })
	//text
};

const ProoviaAdminProvider = ({ children }) => {
	const initialState = {
		logined: false,
		showSider: true,
		overSider: false,
		token: null,
		user: undefined,
		rights: []
	};
	const [state, dispatch] = useReducer(
		// Logger(ProoviaAdminReducer),
		ProoviaAdminReducer,
		initialState
	);

	return (
		<ProoviaAdminStateContext.Provider value={state}>
			<ProoviaAdminDispatchContext.Provider value={dispatch}>
				{children}
			</ProoviaAdminDispatchContext.Provider>
		</ProoviaAdminStateContext.Provider>
	);
};

const usePAState = () => {
	const context = useContext(ProoviaAdminStateContext);
	if (context === undefined) {
		throw new Error('usePAState must be used within a ProoviaAdminReducer');
	}
	return context;
};

const usePADispatch = () => {
	const context = useContext(ProoviaAdminDispatchContext);
	if (context === undefined) {
		throw new Error('usePADispatch must be used within a ProoviaAdminProvider');
	}
	return context;
};

const usePA = () => {
	return [usePAState(), usePADispatch()];
};

export { ProoviaAdminProvider, usePAState, usePADispatch, usePA };
