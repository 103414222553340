import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import SpinFragment from './Components/SpinFragment';
import Authentify from './Authentify';

const LoginPage = lazy(() => import('./Pages/LoginPage'));
const Main = lazy(() => import('./Pages/Main'));

const App = () => {
	return (
		<>
			<Router>
				<Switch>
					<Route path="/login">
						<Suspense fallback={<SpinFragment />}>
							<LoginPage />
						</Suspense>
					</Route>

					<Route path="/">
						<Suspense fallback={<SpinFragment />}>
							<Main />
						</Suspense>
					</Route>
				</Switch>
			</Router>
			<Authentify />
		</>
	);
};

export default App;
