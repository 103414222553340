import { useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { usePADispatch } from './Context/context';

const Authentify = () => {
	const dispatch = usePADispatch();

	useEffect(() => {
		window.addEventListener('storage', () => {
			const token = JSON.parse(localStorage.getItem('token'));

			if (!token || Date.now() > jwt.decode(token?.access_token)?.exp * 1000) {
				localStorage.removeItem('token');
				dispatch({ type: 'RESET', payload: undefined });
			}
		});
		return window.removeEventListener('storage', {});
	}, []);

	return <></>;
};

export default Authentify;
