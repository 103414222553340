import { Spin } from 'antd';

const SpinFragment = () => (
	<div
		style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%'
		}}
	>
		<Spin tip="Loading..." size="large" />
	</div>
);

export default SpinFragment;
